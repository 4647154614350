<template>
  <div id="managePassenger" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">Passenger</div>
        <v-btn class="theme-btn-even" @click.stop="addPassenger()">
          <span class="I-create"></span>
          <span>Add Passenger</span>
        </v-btn>
      </div>
    </div>
    <div id="tableData" class="wrap-Main">
      <div class="box-S4">
        <v-tabs color="white" dark slider-color="red">
          <v-tab>List</v-tab>
          <v-tab>Summary</v-tab>
          <v-tab-item>
            
            <div class="box-S4">
              <v-text-field
                v-model="search"
                label="Search"
                v-on:keyup.enter="SearchPassenger()"
              ></v-text-field>
              <div class="b-action">
                <div class="b-btn">
                  <v-btn @click="SearchPassenger()">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

            <!-- <v-data-table :headers="headers" :search="search" :items="userItem"> -->
            <v-data-table :headers="headers" :items="userItem">
              <template v-slot:item.u_imageurl="{ item }">
                <img class="B-display In-table" :src="item.u_imageurl" />
              </template>
              <template v-slot:item.u_active="{ item }">
                <div class="b-action">
                  <div class="b-btn">
                    <v-btn fab class="btn-edit" @click="editPassenger(item)">
                      <span class="I-edit"></span>
                    </v-btn>
                    <!-- <v-btn fab class="primary btn-delete" @click="onDelete(item)">
                      <span class="I-bin"></span>
                    </v-btn> -->
                  </div>
                </div>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <ejs-grid
              ref="gridReport1"
              id="GridReport1"
              :dataSource="report1"
              :allowGrouping="false"
              :toolbar="toolbarOptions"
              :allowExcelExport="true"
              :toolbarClick="toolbarClickReport1"
              :allowPaging="false"
              :allowSorting="true"
              :allowMultiSorting="true"
            ></ejs-grid>
          </v-tab-item>
        </v-tabs>
      </div>
    </div>
    <div id="partBack" class="wrap-Main">
      <div class="line"></div>
      <div class="box-S4">
        <v-btn text class="ColorTheme-S white--text theme-btn" @click="Back()">
          <span class="I-back"></span>
          <span>Back</span>
        </v-btn>
      </div>
    </div>
  </div>

  <!-- <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">Delete Confirmation ?</span>
              </v-card-title>

              <v-card-text>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex>Delete {{ deleteItem.FirstName }} ?</v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn   @click="closeDialog">Cancel</v-btn>
                <v-btn   :loading="loading" @click="deleteToServer">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>


  <v-btn v-show="user.RoleId===2" color="red darken-1"  @click="closeDialog">Cancel</v-btn>-->
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";
import { PdfLineJoin } from "@syncfusion/ej2-pdf-export";

//Grid จำเป็น
import {
  GridPlugin,
  Page,
  Sort,
  Group,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-vue-grids";

export default {
  data: () => ({
    userdata: null,
    search: "",
    deleteItem: 0,
    dialogDelete: false,
    loading: false,
    userItem: [],
    headers: [
      { value: "u_imageurl", text: "", sortable: false },
      { value: "u_staffcode", text: "Staff Code", sortable: false },
      { value: "u_firstname", text: "First Name", sortable: true },
      { value: "u_lastname", text: "Last Name", sortable: true },
      { value: "u_username", text: "Username", sortable: false },
      { value: "r_name", text: "Position", sortable: false },
      { value: "c_name", text: "Company", sortable: false },
      { value: "o_name", text: "Customer", sortable: false },
      { value: "u_active", text: "", sortable: false },
    ],
    report1: null,
    toolbarOptions: ["ExcelExport"],
  }),
  computed: {},

  mounted: function () {
    this.userdata = this.$CurrentUser.GetCurrentUser();
    this.renderUI();
  },
  provide: {
    grid: [Page, Sort, Group, Toolbar, ExcelExport],
  },
  methods: {
    Back() {
      this.$router.go(-1);
    },
    //ExportExcel
    toolbarClickReport1(args) {
      if (args.item.id === "GridReport1_excelexport") {
        // 'Grid_excelexport' -> Grid component id + _ + toolbar item name
        this.$refs.gridReport1.excelExport();
      }
    },

    closeDialog() {
      this.dialog = false;
      this.dialogDelete = false;
    },
    async onDelete(item) {
      this.deleteItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteToServer(id) {
      try {
        await feathersClientUOA.service("user").remove(this.deleteItem.Id);
        this.renderUI();
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้ (user)" + err);
      } finally {
        this.loading = false;
        this.dialogDelete = false;
      }
    },

    async renderUI() {
      const { user } = await feathersClientUOA.get("authentication");
      try {
        const { user } = await feathersClientUOA.get("authentication");
        //User
        this.search = "";
        const q = {};
        //Flight เห็นหมดทุกคนสามารถขึ้นได้
        if(this.userdata.r_id != 4 && this.userdata.r_id != 5){
          q.r_id = 3;
          q.o_id = this.userdata.o_id;
        }
        q.$limit = 100;

        let res = await feathersClientUOA.service("viewuser").find({ query: q });
        this.userItem = res.data;
      } catch (err) {

      }

      try {
        var role = await feathersClientUOA.service("role").find({});
        this.userStatus = role;

        var company = await feathersClientUOA.service("company").find({});
        this.userStatus = company;
      } catch (err) {
        alert("ไม่สามารถต่อ server ได้ (company)" + err);
      }

      //ปิดชั่วคราว Tab Summary (20220427)
      // try {
      //   var res = await await feathersClientUOA
      //     .service("userofcompany")
      //     .find({});
      //   this.report1 = res[0].data;
      // } catch (err) {
      //   alert("ไม่สามารถต่อ server ได้ (userofcompany)" + err);
      // }
    },
    addPassenger() {
      this.$router.push({
        path: "/userprofile",
        query: { u_id: 0, title: "Add Passenger", redirect: "/passenger" },
      });
    },
    editPassenger(item) {
      this.$router.push({
        path: "/userprofile",
        query: {
          u_id: item.u_id,
          title: "Edit Passenger",
          redirect: "/passenger",
        },
      });
    },

    async SearchPassenger() {
      try {
        if (this.search != "") {
          const q = {};
          //Flight เห็นหมดทุกคนสามารถขึ้นได้
          if (this.userdata.r_id != 4 && this.userdata.r_id != 5 && this.userdata.r_id != 21) {
            q.r_id = 3;
            q.o_id = this.userdata.o_id;
          }
          q.u_firstname = { $like: this.search + "%" };
          q.$limit = 100;

          let res = await feathersClientUOA
            .service("viewuser")
            .find({ query: q });
          this.userItem = res.data;
        }
      } catch (err) {}
    },
  },
};
</script>